export const buttonStyles = {
  variant: "unstyled",
  minW: "180px",
  h: "auto",
  p: "0.75rem 1.5rem",
  lineHeight: "1.5rem",
  fontWeight: 600,
  fontSize: "fs14",
  borderRadius: "2rem",
  color: "white",
  background: "linear-gradient(90deg, #019EEC 0%, #015EAE 100%)",
  _active: {
    transition: "0.3s ease",
    transform: "translateY(1px)"
  },
  _hover: {
    transition: "0.2s ease",
    opacity: "0.9",
    boxShadow: "inset 0px 0px 25px -10px rgba(66, 68, 90, 1)"
  },
  _disabled: {
    background: "neutral.300",
    cursor: "not-allowed",
    boxShadow: "none",
    transform: "none",
    opacity: 1
  }
};

export const buttonStylesLight = {
  variant: "unstyled",
  h: "auto",
  color: "blue.700",
  fontSize: "fs14",
  lineHeight: "1.5rem",
  borderWidth: "1px",
  borderColor: "neutral.200",
  p: "0.75rem 2rem",
  fontWeight: 600,
  borderRadius: "2rem",
  background: "white",
  _hover: {
    transition: "0.2s ease",
    background: "blue.light"
  },
  _active: {
    transition: "0.2s ease",
    background: "#D9F2FA"
  }
};
