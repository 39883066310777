import {
  Button,
  Card,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { Link as ChakraLink } from "@chakra-ui/react";
import { FailedFetchingAlert } from "components";
import { Link, Link as ReactRouterLink } from "react-router-dom";

import { PathParts } from "routes";
import Badge from "components/badge/Badge";
import { CustomSpinner } from "components/customSpinner/CustomSpinner";

import { useGetSupplies } from "../hooks/useGetSupplies";
import { buttonStyles } from "../styles";
import { SupplyColumns, SupplyStatus } from "../types";

import { columnLabels, columns } from "./consts";
import {
  prepareTableData,
  statusToBadgeColorMap,
  statusToBadgeLabelMap
} from "./utils";

export const SupplyList = () => {
  const { isLoading, data, error } = useGetSupplies();

  if (isLoading) {
    return (
      <Flex h="90vh" alignItems="center" justifyContent="center">
        <CustomSpinner />
      </Flex>
    );
  }

  if (error) {
    return <FailedFetchingAlert message="Nieudane pobieranie listy zamówień" />;
  }

  if (!data) {
    return null;
  }

  const preparedTableData = prepareTableData(data);

  return (
    <Flex maxW="1280px" m="auto" flexDir="column">
      <Button
        {...buttonStyles}
        mb="1.5rem"
        ml="auto"
        as={Link}
        to={`${PathParts.CREATE}`}
      >
        Dodaj nowe zamówienie
      </Button>
      <Card p="1.5rem" variant="elevated">
        <Text color="neutral.950" fontSize="1.375rem" fontWeight={700}>
          Lista zamówień
        </Text>

        <TableContainer maxWidth="100vw" mt="2.25rem">
          <Table size="sm">
            <Thead>
              <Tr>
                {columns.map(columnKey => (
                  <Th p="1.5rem 1rem" color="neutral.500" key={columnKey}>
                    {columnLabels[columnKey]}
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody>
              {preparedTableData.map(supplyItem => (
                <Tr
                  sx={{ "&:nth-of-type(even)": { bg: "neutral.50" } }}
                  key={supplyItem.LP}
                >
                  {Object.entries(supplyItem).map(([key, value]) => {
                    if (key === SupplyColumns.STATUS) {
                      return (
                        <Td p="1rem" color="neutral.800" key={key}>
                          <Badge
                            colorVariant={
                              statusToBadgeColorMap.get(
                                value as SupplyStatus
                              ) || "neutral"
                            }
                          >
                            {statusToBadgeLabelMap.get(value as SupplyStatus)}
                          </Badge>
                        </Td>
                      );
                    }

                    if (key === SupplyColumns.SUPPLY_ID) {
                      return (
                        <Td p="1rem" color="neutral.800" key={key}>
                          <ChakraLink
                            as={ReactRouterLink}
                            to={String(supplyItem.id)}
                            color="blue.dark"
                            _hover={{ textDecoration: "none" }}
                          >
                            {value}
                          </ChakraLink>
                        </Td>
                      );
                    }

                    if (key === SupplyColumns.ID) {
                      return null;
                    }

                    return (
                      <Td p="1rem" color="neutral.800" key={key}>
                        {value}
                      </Td>
                    );
                  })}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Card>
    </Flex>
  );
};
