export enum Routes {
  STORES = "/stores",
  STORE_CHAINS = "/store-chains",
  APP_CONFIG_KEYS = "/app-config-keys",
  ENERGY_DRINKS = "/energy-drinks",
  COMPLAINTS = "/complaints",
  SUPPLIES = "/supplies"
  // TODO: uncomment when pages are created
  // CLIENTS = "/clients",
  // ORDERS = "/orders",
  // SESSIONS = "/sessions",
  // PAYMENTS = "/payments",
  // PRODUCTS = "/products",
}

export enum PathParameters {
  STORE_ID = "storeId",
  STORE_CHAIN_ID = "storeChainId",
  STORE_APP_CONFIG_KEY_ID = "storeAppConfigKeyId",
  COMPLAINT_ID = "complaintId",
  SUPPLY_ID = "supplyId"
}

export enum PathParts {
  EDIT = "edit",
  CREATE = "create"
}
