import { useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast
} from "@chakra-ui/react";
import { format } from "date-fns";
import isEmpty from "lodash/isEmpty";
import mapKeys from "lodash/mapKeys";
import Papa from "papaparse";
import ReactDatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";

import { commonToastSetup } from "consts";
import { Routes } from "routes";
import { TDate } from "components/customDatePicker/CustomDatePicker";
import { datePickerCustomStyles } from "utils/datePickerCustomStyles";

import { useCreateSupply } from "../hooks/useCreateSupply";
import { buttonStyles, buttonStylesLight } from "../styles";
import { Product } from "../types";

import { columnLabels, columns, csvColumnTranslationMap } from "./consts";
import NewSupplyModal from "./NewSupplyModal";
import { prepareSupplyPayload, validateSapId } from "./utils";

type ExpandedRecord = Product & {
  LP: number;
};

export const SupplyCreate = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [requestedDate, setRequestedDate] = useState<TDate>();
  const [csvFileName, setCsvFileName] = useState("");
  const [parsedData, setParsedData] = useState<Product[]>([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { fetchData: createSupplyRequest, isLoading } = useCreateSupply();

  const getRecordBackgroundColor = (baseColor: string, index: number) => {
    return Object.keys(errors).includes(String(index + 1))
      ? "rgba(255, 19, 19, 0.5)"
      : baseColor;
  };

  const getSubmitButtonDisabledState = () => {
    if (isLoading) {
      return true;
    }

    if (
      Boolean(requestedDate) &&
      isEmpty(errors) &&
      parsedData &&
      parsedData.length > 0
    ) {
      return false;
    }

    return true;
  };

  const handleSendSupplyRequest = async () => {
    if (!requestedDate) {
      return;
    }

    const payload = prepareSupplyPayload(parsedData, requestedDate);
    try {
      const res = await createSupplyRequest({
        body: { ...payload, supplyType: "H" }
      });

      if (res.data) {
        toast({
          title: "Sukces",
          description: "Utworzono nowe zamówienie",
          status: "success",
          ...commonToastSetup
        });

        navigate(`${Routes.SUPPLIES}/`);
      }

      if (res.error) {
        if (res.error.content.errorMessage) {
          toast({
            title: "Wystąpił błąd przy tworzeniu zamówienia.",
            description: res.error.content.errorMessage,
            status: "error",
            ...commonToastSetup
          });
        } else {
          toast({
            title: "Wystąpił błąd przy tworzeniu zamówienia.",
            status: "error",
            ...commonToastSetup
          });
        }
      }
    } catch (err) {
      toast({
        title: "Wystąpił błąd",
        status: "error",
        ...commonToastSetup
      });
    }
  };

  const processUploadedFile = (currentFile: File) => {
    if (!currentFile) {
      return;
    }

    setErrors({});
    setCsvFileName(currentFile.name);

    Papa.parse<Record<string, string>>(currentFile, {
      header: true,
      skipEmptyLines: true,
      complete: parsingResults => {
        const values = parsingResults.data.map(item => {
          const transformedItem: Product = {
            mpk: "",
            sapId: "",
            name: "",
            outcomeUnit: "",
            productGroup: "",
            orderedQuantity: ""
          };

          const lowerCaseItem = mapKeys(item, (_, key) => key.toLowerCase());

          csvColumnTranslationMap.forEach((newKey, oldKey) => {
            const value = lowerCaseItem[oldKey.toLowerCase()];

            if (value !== undefined) {
              transformedItem[newKey] = value;
            }
          });

          return transformedItem;
        });

        const valuesWithOrderingNumber = values.map((item, index) => ({
          LP: index + 1,
          ...item
        })) as ExpandedRecord[];

        valuesWithOrderingNumber.forEach(item => {
          const quantity = item.orderedQuantity;
          const sapId = item.sapId;
          const isSapIdValid = validateSapId(sapId);

          if (!quantity || !isSapIdValid) {
            setErrors(prevErrors => ({
              ...prevErrors,
              [item.LP]: "Error"
            }));
          }
        });

        setParsedData(values);
      }
    });
  };

  return (
    <Flex maxW="1280px" m="auto" flexDir="column">
      <Flex w="100%" gap="1.5rem">
        <Card variant="elevated" w="50%">
          <Flex p="1.5rem 2.5rem" alignItems="center">
            <Text fontSize="fs22" fontWeight={700}>
              Wnioskowana data realizacji
            </Text>
          </Flex>
          <Divider />
          <Flex p="2.25rem 2.5rem 2rem 2.5rem">
            <Text
              color="neutral.800"
              fontSize="fs12"
              fontWeight={600}
              lineHeight="16px"
            >
              Data
            </Text>
            <Text
              color={requestedDate ? "blue.700" : "neutral.500"}
              fontSize="fs12"
              fontWeight={600}
              lineHeight="16px"
              ml="auto"
            >
              {requestedDate ? format(requestedDate, "dd.MM.yyyy") : "Wybierz"}
            </Text>
          </Flex>
          <Flex justifyContent="flex-end" p="0 2.5rem 1.5rem 2.5rem">
            <Box maxW="200px" sx={datePickerCustomStyles}>
              <ReactDatePicker
                selected={requestedDate}
                onChange={date => {
                  setRequestedDate(date);
                }}
                placeholderText="test"
                customInput={
                  <Button
                    height="48px"
                    {...buttonStylesLight}
                    p="0.75rem 1.5rem"
                  >
                    {requestedDate ? "Zmień datę" : "Wybierz datę"}
                  </Button>
                }
                showPopperArrow={false}
              />
            </Box>
          </Flex>
        </Card>
        <Card variant="elevated" w="50%">
          <Flex p="1.5rem 2.5rem" alignItems="center">
            <Text fontSize="fs22" fontWeight={700}>
              Twój plik .csv
            </Text>
          </Flex>
          <Divider />
          <Flex p="2.25rem 2.5rem 2rem 2.5rem">
            <Text
              color="neutral.800"
              fontSize="fs12"
              fontWeight={600}
              lineHeight="16px"
            >
              Nazwa pliku
            </Text>
            <Text
              color={parsedData ? "blue.700" : "neutral.500"}
              fontSize="fs12"
              fontWeight={600}
              lineHeight="16px"
              ml="auto"
            >
              {csvFileName && parsedData && parsedData.length > 0
                ? csvFileName
                : "Wybierz"}
            </Text>
          </Flex>
          <Flex justifyContent="flex-end" p="0 2.5rem 1.5rem 2.5rem">
            <Box maxW="200px" sx={datePickerCustomStyles}>
              <NewSupplyModal
                buttonText={
                  csvFileName && parsedData && parsedData.length > 0
                    ? "Dodaj inny plik"
                    : "Dodaj plik"
                }
                processUploadedFile={processUploadedFile}
              />
            </Box>
          </Flex>
        </Card>
      </Flex>
      <Card variant="elevated" p="1.5rem" mt="1.5rem">
        <Text fontSize="fs22" fontWeight={700} color="neutral.950" mb="1rem">
          Pozycje do zamówienia (z pliku .csv)
        </Text>
        <Text fontSize="fs12" color="neutral.700" fontWeight={600} mb="1.5rem">
          Sprawdź czy pozycje z Twojego wgranego pliku są właściwe
        </Text>

        <TableContainer maxWidth="100vw" overflowX="auto">
          <Table size="sm" variant="simple">
            <Thead>
              <Tr>
                {columns.map(columnKey => (
                  <Th p="1.5rem 1rem" color="neutral.500" key={columnKey}>
                    {columnLabels[columnKey]}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {parsedData &&
                parsedData.map((record, index) => {
                  return (
                    <Tr
                      sx={{
                        "&:nth-of-type(even)": {
                          backgroundColor: getRecordBackgroundColor(
                            "neutral.50",
                            index
                          )
                        },
                        backgroundColor: getRecordBackgroundColor(
                          "transparent",
                          index
                        )
                      }}
                      key={index}
                    >
                      {Object.values(record).map((val: any, i: number) => {
                        return (
                          <Td p="1rem" h="4.5rem" color="neutral.800" key={i}>
                            {val}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      </Card>
      <Flex m="3rem 0">
        <Flex width="100%" justifyContent="flex-end">
          {parsedData && parsedData.length > 0 && (
            <Button
              {...buttonStylesLight}
              w="18rem"
              onClick={() => {
                navigate(Routes.SUPPLIES);
                window.scrollTo(0, 0);
              }}
            >
              Anuluj tworzenie zamówienia
            </Button>
          )}
          <Button
            {...buttonStyles}
            isDisabled={getSubmitButtonDisabledState()}
            ml="0.75rem"
            w="18rem"
            onClick={handleSendSupplyRequest}
          >
            Zleć zamówienie
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
