import { API_ROOT_SAPOLLO } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";

import { SupplyStatus } from "../types";

export const useChangeSupplyStatus = (id?: string) => {
  const fetch = useFetchRefactored<{ status: SupplyStatus }, null>({
    url: `${API_ROOT_SAPOLLO}/supplies/${id}/status`,
    method: "PUT"
  });
  return fetch;
};
