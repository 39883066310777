import { useCallback, useState } from "react";
import { Box, Text, VStack } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";

const FileDropzone = ({ onDrop }: { onDrop: (files: File[]) => void }) => {
  const [fileName, setFileName] = useState("");
  const onDropCallback = useCallback(
    (files: File[]) => {
      setFileName(files[0].name);
      onDrop(files);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropCallback,
    accept: {
      "text/csv": [".csv"]
    },
    multiple: false
  });

  return (
    <Box
      {...getRootProps()}
      w="100%"
      minH="140px"
      borderWidth="1px"
      borderRadius="12px"
      borderColor={isDragActive ? "blue.400" : "neutral.300"}
      borderStyle="dashed"
      bg={isDragActive ? "gray.50" : "transparent"}
      p="2.5rem"
      textAlign="center"
      cursor="pointer"
      transition="0.2s ease"
      _hover={{ borderColor: "blue.dark" }}
    >
      <input {...getInputProps()} />
      <VStack spacing={4}>
        <Text fontSize="fs16" fontWeight={600} color="neutral.950">
          {fileName ? fileName : " Plik .csv"}
        </Text>
        <Box color="neutral.600" fontSize="fs14">
          {isDragActive ? (
            "Upuść tutaj"
          ) : (
            <Box>
              Przeciągnij i upuść lub{" "}
              <Text as="span" color="blue.dark">
                Dodaj plik
              </Text>
            </Box>
          )}
        </Box>
      </VStack>
    </Box>
  );
};

export default FileDropzone;
