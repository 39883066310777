import * as Yup from "yup";

import { EStoreChain } from "types/configFields";

import { componentsSchema } from "./componentsSchema";
import { openingScheduleSchema } from "./openingScheduleSchema";
import { parametersSchema } from "./parametersSchema";
import { servicesSchema } from "./servicesSchema";

export const requiredMessage = "To pole nie może być puste";

export const validationSchema = Yup.object().shape({
  [EStoreChain.NAME]: Yup.string().required(requiredMessage),
  [EStoreChain.CHAIN_ID]: Yup.string().required(requiredMessage),
  // [EStoreChain.MASTER_ID]: Yup.string(),
  [EStoreChain.PARAMETERS]: parametersSchema,
  [EStoreChain.SERVICES]: servicesSchema,
  [EStoreChain.COMPONENTS]: componentsSchema,
  [EStoreChain.OPENING_SCHEDULE]: openingScheduleSchema
});
