import { ProductColumns } from "../types";

export const columnLabels = {
  [ProductColumns.SAP_ID]: "Indeks",
  [ProductColumns.NAME]: "Nazwa",
  [ProductColumns.OUTCOME_UNIT]: "Jednostka wydania",
  [ProductColumns.PRODUCT_GROUP]: "Grupa towarowa",
  [ProductColumns.ORDERED_QUANTITY]: "Ilość do zamówienia",
  LP: "LP"
};

export const columns = [
  "LP",
  ProductColumns.SAP_ID,
  ProductColumns.NAME,
  ProductColumns.OUTCOME_UNIT,
  ProductColumns.PRODUCT_GROUP,
  ProductColumns.ORDERED_QUANTITY
] as const;
