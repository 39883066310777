import { Flex, Text } from "@chakra-ui/react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { EFieldType } from "components/formField/FormField";
import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import {
  cardStyles,
  sectionStyles,
  stickyHeadingStyles
} from "pages/store/styles";
import { EStoreChain } from "types/configFields";

import StoreChainField from "../components/StoreChainField";

const GeneralInfo = () => {
  const { isEdit } = useFieldGenerationContext();

  return (
    <>
      <Flex {...sectionStyles} mb="1rem">
        <Text id="general" {...stickyHeadingStyles} borderRadius=".5rem">
          Informacje ogólne
        </Text>
      </Flex>
      <BorderedBox m="1rem" pb="1rem">
        <Flex {...cardStyles}>
          <StoreChainField
            isSimpleField
            name={EStoreChain.NAME}
            fieldType={EFieldType.TEXT}
          />

          <StoreChainField
            isSimpleField
            name={EStoreChain.CHAIN_ID}
            fieldType={EFieldType.TEXT}
            isDisabled={isEdit}
          />

          {/* <StoreChainField
            isSimpleField
            name={EStoreChain.MASTER_ID}
            fieldType={EFieldType.TEXT}
          /> */}
        </Flex>
      </BorderedBox>
    </>
  );
};

export default GeneralInfo;
