import { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { FailedFetchingAlert } from "components";
import { format } from "date-fns";
import { useParams } from "react-router-dom";

import Badge from "components/badge/Badge";
import { CustomSpinner } from "components/customSpinner/CustomSpinner";
import LineItem from "components/lineItem/LinteItem";

import { useChangeSupplyStatus } from "../hooks/useChangeSupplyStatus";
import { useGetSupplyDetails } from "../hooks/useGetSupplyDetails";
import { buttonStyles } from "../styles";
import {
  currentToNewStatusMap,
  statusToBadgeColorMap,
  statusToBadgeLabelMap
} from "../SupplyList/utils";
import { SupplyStatus } from "../types";

import { columnLabels, columns } from "./consts";
import { prepareTableData, statusToButtonLabelMap } from "./utils";

export const SupplyDetails = () => {
  const { supplyId } = useParams<{ supplyId: string }>();
  const { fetchData, data, isLoading, error } = useGetSupplyDetails(supplyId);
  const { fetchData: changeStatusRequest, isLoading: changeStatusIsLoading } =
    useChangeSupplyStatus(supplyId);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data && isLoading) {
    return (
      <Flex h="90vh" alignItems="center" justifyContent="center">
        <CustomSpinner />
      </Flex>
    );
  }

  if (error) {
    return (
      <FailedFetchingAlert message="Nieudane pobieranie szczegółów dostawy" />
    );
  }

  if (!data) {
    return null;
  }

  const products = data.products;
  const currentStatus = data.status;
  const preparedTableData = prepareTableData(products);

  const handleChangeStatusButton = async () => {
    if (currentStatus === SupplyStatus.FINISHED) {
      return;
    }

    const res = await changeStatusRequest({
      body: { status: currentToNewStatusMap.get(currentStatus) as SupplyStatus }
    });

    if (res) {
      fetchData();
    }
  };

  return (
    <Flex maxW="1280px" m="auto" flexDir="column">
      <Button
        {...buttonStyles}
        ml="auto"
        mb="1.5rem"
        onClick={handleChangeStatusButton}
        isDisabled={currentStatus === SupplyStatus.FINISHED}
      >
        {statusToButtonLabelMap.get(data.status)}
      </Button>
      <Card variant="elevated">
        <Flex p="24px 40px" alignItems="center">
          <Text fontSize="fs22" fontWeight={700}>
            Szczegóły zamówienia
          </Text>
        </Flex>
        <Divider />
        <Flex p="24px 40px" flexDir="column" gap="1.5rem">
          <LineItem label="ID zgłoszenia" value={data.supplyId} />
          <LineItem
            label="Data złożenia zamówienia"
            value={`${format(data.supplyDate, "dd.MM.yyyy")}, ${data.supplyTime}`}
            hasCopyButton={false}
          />
          <LineItem
            label="Status"
            customContent={() => {
              if (data && (changeStatusIsLoading || isLoading)) {
                return (
                  <Box maxH="40px" mr="2rem">
                    <CustomSpinner h="40px" size="md" />
                  </Box>
                );
              } else {
                return (
                  <Badge
                    colorVariant={
                      statusToBadgeColorMap.get(data.status) || "neutral"
                    }
                  >
                    {statusToBadgeLabelMap.get(data.status) || "Nieznany"}
                  </Badge>
                );
              }
            }}
          />
          <LineItem
            label="Wnioskowana data realizacji"
            value={format(data.requestedDeliveryDate, "dd.MM.yyyy")}
            hasCopyButton={false}
          />
        </Flex>
      </Card>
      <Card variant="elevated" mt="0.75rem" p="1.5rem">
        <Box p="24px">
          <Text fontSize="fs22" fontWeight={700}>
            Lista produktów
          </Text>
        </Box>

        <TableContainer maxWidth="100vw" mt="2.25rem">
          <Table size="sm">
            <Thead>
              <Tr>
                {columns.map(columnKey => (
                  <Th p="1.5rem 1rem" color="neutral.500" key={columnKey}>
                    {columnLabels[columnKey]}
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody>
              {preparedTableData.map(supplyItem => (
                <Tr
                  sx={{ "&:nth-of-type(even)": { bg: "neutral.50" } }}
                  key={supplyItem.sapId}
                >
                  {Object.entries(supplyItem).map(([key, value]) => {
                    return (
                      <Td p="1rem" h="4.5rem" color="neutral.800" key={key}>
                        {value}
                      </Td>
                    );
                  })}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Card>
    </Flex>
  );
};
