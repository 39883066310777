import { format } from "date-fns";

import { ColorVariant } from "components/badge/Badge";

import { Supply, SupplyColumns, SupplyStatus } from "../types";

export const prepareTableData = (data: Supply[]) =>
  data.map((item, index) => {
    return {
      LP: index + 1,
      [SupplyColumns.ID]: item.id,
      [SupplyColumns.SUPPLY_ID]: item.supplyId,
      [SupplyColumns.SUPPLY_DATE]: format(item.supplyDate, "dd.MM.yyyy"),
      [SupplyColumns.STATUS]: item.status,
      [SupplyColumns.REQUESTED_DELIVERY_DATE]: format(
        item.requestedDeliveryDate,
        "dd.MM.yyyy"
      )
    };
  });

export const statusToBadgeLabelMap = new Map<SupplyStatus, string>([
  [SupplyStatus.SENT, "WYSŁANE"],
  [SupplyStatus.CONFIRMED, "POTWIERDZONE"],
  [SupplyStatus.FINISHED, "ZAKOŃCZONE"]
]);

export const statusToBadgeColorMap = new Map<SupplyStatus, ColorVariant>([
  [SupplyStatus.SENT, "neutral"],
  [SupplyStatus.CONFIRMED, "orange"],
  [SupplyStatus.FINISHED, "green"]
]);

export const currentToNewStatusMap = new Map<SupplyStatus, SupplyStatus | null>(
  [
    [SupplyStatus.SENT, SupplyStatus.CONFIRMED],
    [SupplyStatus.CONFIRMED, SupplyStatus.FINISHED],
    [SupplyStatus.FINISHED, null]
  ]
);
