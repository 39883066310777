import { API_ROOT_SAPOLLO } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";

import { Product } from "../types";

interface SupplyCreatePayload {
  requestedDeliveryDate: string;
  supplyType: string;
  products: Omit<Product, "mpk">[];
}

export const useCreateSupply = () =>
  useFetchRefactored<SupplyCreatePayload, null>({
    url: `${API_ROOT_SAPOLLO}/supplies/new`,
    method: "POST"
  });
