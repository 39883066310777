import { SupplyColumns } from "../types";

export const columnLabels = {
  [SupplyColumns.SUPPLY_ID]: "Id zamówienia",
  [SupplyColumns.SUPPLY_DATE]: "Data złożenia zamówienia",
  [SupplyColumns.STATUS]: "Status",
  [SupplyColumns.REQUESTED_DELIVERY_DATE]: "Wnioskowana data realizacji",
  LP: "LP"
};

export const columns = [
  "LP",
  SupplyColumns.SUPPLY_ID,
  SupplyColumns.SUPPLY_DATE,
  SupplyColumns.STATUS,
  SupplyColumns.REQUESTED_DELIVERY_DATE
] as const;
