import * as Yup from "yup";

import {
  EComponent,
  EComponentProperty,
  EMaintenanceBreak,
  EMonitor,
  EPosType
} from "types/configFields";

import { provideNumberMessage, requiredMessage } from "./consts";

const boundaries100Error = "Ustaw wartość pomiędzy 0 a 100";

const monitorSchema = Yup.object()
  .nullable()
  .shape({
    [EMonitor.IS_ENABLED]: Yup.boolean().optional(),
    [EMonitor.IS_DISABLED_OUTSIDE_WORKING_HOURS]: Yup.boolean(),
    [EMonitor.ACTIONS]: Yup.object()
      .nullable()
      .shape({
        [EMonitor.STORE_STATE_MODIFIER]: Yup.object().shape({
          [EMonitor.IS_ACTIVE]: Yup.boolean(),
          [EMonitor.CLOSE_THRESHOLD]: Yup.number()
            .typeError(provideNumberMessage)
            .min(0, boundaries100Error)
            .max(100, boundaries100Error),
          [EMonitor.CLOSE_PERIOD]: Yup.number().typeError(provideNumberMessage),
          [EMonitor.OPEN_THRESHOLD]: Yup.number()
            .typeError(provideNumberMessage)
            .min(0, boundaries100Error)
            .max(100, boundaries100Error),
          [EMonitor.OPEN_PERIOD]: Yup.number().typeError(provideNumberMessage)
        })
      })
  });

const aifiSchema = Yup.object()
  .required()
  .shape({
    [EComponentProperty.SERVICE_KEY]: Yup.string().required(requiredMessage),
    // [EComponentProperty.CLUSTER_ID]: Yup.string(),
    [EComponentProperty.MONITORS]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.STATE]: monitorSchema
      })
  });

const adienSchema = Yup.object()
  .required()
  .shape({
    [EComponentProperty.MERCHANT_ACCOUNT]:
      Yup.string().required(requiredMessage),
    [EComponentProperty.SERVICE_KEY]: Yup.string().required(requiredMessage),
    [EComponentProperty.DEFAULT_LOCALE]: Yup.string().required(requiredMessage),
    [EComponentProperty.MONITORS]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.AVAILABILITY]: monitorSchema
      })
  });

const maintenanceBreakSchema = Yup.object().shape({
  [EMaintenanceBreak.TYPE]: Yup.string().required(requiredMessage),
  [EMaintenanceBreak.STARTS_AT]: Yup.string().required(requiredMessage),
  [EMaintenanceBreak.ENDS_AT]: Yup.string().required(requiredMessage)
});

const posSchema = Yup.object()
  .required()
  .shape({
    [EComponentProperty.SERVICE_KEY]: Yup.string().required(requiredMessage),
    [EComponentProperty.TYPE]: Yup.string().required(requiredMessage),
    [EComponentProperty.MONITORS]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.AVAILABILITY]: monitorSchema,
        [EComponentProperty.PAPER_STATE]: monitorSchema
      }),
    [EComponentProperty.POS_SERVICE]: Yup.object().shape({
      [EComponentProperty.MAINTENANCE_BREAKS]: Yup.array()
        .nullable()
        .of(maintenanceBreakSchema)
    }),
    [EComponentProperty.IPOS]: Yup.object().shape({
      [EComponentProperty.VAT_RATES]: Yup.string().test(
        "is-required-based-on-type",
        requiredMessage,
        function (value, context) {
          const grandparent = context.from ? context.from[1] : null;

          if (grandparent && grandparent.value) {
            if (grandparent.value.type === EPosType.IPOS) {
              return !!value;
            }
            return true;
          }
        }
      )
    })
  });

const infokioskSchema = Yup.object()
  .required()
  .shape({
    [EComponentProperty.SERVICE_KEY]: Yup.string().required(requiredMessage),
    [EComponentProperty.HEARTBEAT_THRESHOLD]:
      Yup.number().typeError(provideNumberMessage),
    [EComponentProperty.MONITORS]: Yup.object()
      .required()
      .shape({
        [EComponentProperty.AVAILABILITY]: monitorSchema
      })
  });

const powerBouncerSchema = Yup.object()
  .optional()
  .shape({
    [EComponentProperty.TOKEN]: Yup.string(),
    [EComponentProperty.HELP_LINK]: Yup.string(),
    [EComponentProperty.HEARTBEAT_THRESHOLD]: Yup.number(),
    [EComponentProperty.IO_CONTROLLER]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.SERVICE_KEY]: Yup.string()
      }),
    [EComponentProperty.AGE_ESTIMATION]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.MIN_ADULT_AGE]: Yup.number()
      }),
    [EComponentProperty.MONITORS]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.AVAILABILITY]: monitorSchema,
        [EComponentProperty.QR_READER_AVAILABILITY]: monitorSchema
      })
  });

export const componentsSchema = Yup.object()
  .required()
  .shape({
    [EComponent.AIFI]: aifiSchema,
    [EComponent.ADYEN]: adienSchema,
    [EComponent.POS]: posSchema,
    [EComponent.INFOKIOSK]: infokioskSchema,
    [EComponent.POWER_BOUNCER]: powerBouncerSchema
  });
