import { ReactNode } from "react";
import {
  Badge as ChakraBadge,
  BadgeProps as ChakraBadgeProps
} from "@chakra-ui/react";

export type ColorVariant = "neutral" | "orange" | "green";

interface BadgeProps extends ChakraBadgeProps {
  children: ReactNode;
  colorVariant: ColorVariant;
}

const Badge = ({ children, colorVariant, ...rest }: BadgeProps) => {
  const getColors = () => {
    switch (colorVariant) {
      case "orange":
        return {
          color: "orange.500",
          bg: "orange.50"
        };
      case "green":
        return {
          color: "green.600",
          bg: "green.50"
        };
      case "neutral":
      default:
        return {
          color: "neutral.600",
          bg: "neutral.100"
        };
    }
  };

  return (
    <ChakraBadge
      variant="subtle"
      borderRadius="1rem"
      padding="0.5rem 1rem"
      justifyContent="center"
      alignItems="center"
      textTransform="uppercase"
      fontSize="0.75rem"
      {...getColors()}
      {...rest}
    >
      {children}
    </ChakraBadge>
  );
};

export default Badge;
