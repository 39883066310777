export enum SupplyStatus {
  SENT = "SENT",
  CONFIRMED = "CONFIRMED",
  FINISHED = "FINISHED"
}

export enum SupplyColumns {
  ID = "id",
  SUPPLY_ID = "supplyId",
  SUPPLY_DATE = "supplyDate",
  SUPPLY_TIME = "supplyTime",
  STATUS = "status",
  REQUESTED_DELIVERY_DATE = "requestedDeliveryDate"
}

export interface Supply {
  id: string;
  supplyId: string;
  supplyDate: string;
  supplyTime: string;
  requestedDeliveryDate: string;
  status: SupplyStatus;
}

export enum ProductColumns {
  MPK = "mpk",
  SAP_ID = "sapId",
  NAME = "name",
  OUTCOME_UNIT = "outcomeUnit",
  ORDERED_QUANTITY = "orderedQuantity",
  PRODUCT_GROUP = "productGroup"
}

export interface Product {
  mpk: string;
  sapId: string;
  name: string;
  outcomeUnit: string;
  orderedQuantity: string;
  productGroup: string;
}

export interface SupplyDetailsResponse {
  supplyId: string;
  supplyDate: string;
  supplyTime: string;
  supplyType: string;
  status: SupplyStatus;
  requestedDeliveryDate: string;
  confirmationDate: string;
  confirmationDocId: string;
  invoiceId: string;
  products: Product[];
}
