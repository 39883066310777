import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Box } from "@chakra-ui/react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { isProduction } from "utils";

import { Routes as AppRoutes, PathParameters, PathParts } from "routes";
import { Header } from "components/header/Header";
import { StoreChainContextProvider } from "contexts/StoreChainContext";
import { StoreContextProvider } from "contexts/StoreContext";
import { useRoles } from "hooks/roles/useRoles";
import { AppConfigKeysCreate } from "pages/appConfigKey/AppConfigKeysCreate";
import { AppConfigKeysEdit } from "pages/appConfigKey/AppConfigKeysEdit";
import { AppConfigKeysPreview } from "pages/appConfigKey/AppConfigKeysPreview";
import { AppConfigKeys } from "pages/appConfigKeys/AppConfigKeys";
import { ComplaintPreview } from "pages/complaint/ComplaintPreview";
import { Complaints } from "pages/complaints/Complaints";
import { EnergyDrinks } from "pages/energyDrinks/EnergyDrinks";
import { Store } from "pages/store/Store";
import StoreCreate from "pages/store/StoreCreate";
import StoreEdit from "pages/store/StoreEdit";
import { StoreChain } from "pages/storeChain/StoreChain";
import { StoreChainCreate } from "pages/storeChain/StoreChainCreate";
import StoreChainEdit from "pages/storeChain/StoreChainEdit";
import { StoreChains } from "pages/storeChains/StoreChains";
import { Stores } from "pages/stores/Stores";
import { SupplyCreate } from "pages/supplies/SupplyCreate/SupplyCreate";
import { SupplyDetails } from "pages/supplies/SupplyDetails/SupplyDetails";
import { SupplyList } from "pages/supplies/SupplyList/SupplyList";

import { loginRequest } from "./authConfig";

const isProd = isProduction();

function App(): JSX.Element {
  const {
    canReadStores,
    canReadStoreDetails,
    canReadStoreChains,
    canReadEnergyDrinks,
    canEditAppConfigKeys,
    canEditStoreChains,
    canManageSupply
  } = useRoles();

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        <StoreContextProvider>
          <Header />
          <Box padding={{ base: "2rem 1rem", lg: "2rem 3rem" }}>
            <Routes>
              {canReadStores && (
                <>
                  <Route
                    path="/"
                    element={<Navigate to={AppRoutes.STORES} />}
                  />
                  <Route path={`${AppRoutes.STORES}/`} element={<Stores />} />

                  {canReadStoreDetails && (
                    <Route
                      path={`${AppRoutes.STORES}/:${PathParameters.STORE_ID}`}
                      element={<Store />}
                    />
                  )}
                  <Route
                    path={`${AppRoutes.STORES}/:${PathParameters.STORE_ID}/${PathParts.EDIT}`}
                    element={<StoreEdit />}
                  />
                  <Route
                    path={`${AppRoutes.STORES}/${PathParts.CREATE}`}
                    element={<StoreCreate />}
                  />
                </>
              )}

              {canReadStoreChains && (
                <>
                  <Route
                    path="/"
                    element={<Navigate to={AppRoutes.STORE_CHAINS} />}
                  />
                  <Route
                    path={`${AppRoutes.STORE_CHAINS}/`}
                    element={<StoreChains />}
                  />
                  <Route
                    element={
                      <StoreChainContextProvider>
                        <Outlet />
                      </StoreChainContextProvider>
                    }
                  >
                    <Route
                      path={`${AppRoutes.STORE_CHAINS}/:${PathParameters.STORE_CHAIN_ID}`}
                      element={<StoreChain />}
                    />
                    {canEditStoreChains && (
                      <>
                        <Route
                          path={`${AppRoutes.STORE_CHAINS}/${PathParts.CREATE}`}
                          element={<StoreChainCreate />}
                        />
                        <Route
                          path={`${AppRoutes.STORE_CHAINS}/:${PathParameters.STORE_CHAIN_ID}/${PathParts.EDIT}`}
                          element={<StoreChainEdit />}
                        />
                      </>
                    )}
                  </Route>
                </>
              )}

              {canEditAppConfigKeys && (
                <>
                  <Route
                    path="/"
                    element={<Navigate to={AppRoutes.APP_CONFIG_KEYS} />}
                  />
                  <Route
                    path={`${AppRoutes.APP_CONFIG_KEYS}/`}
                    element={<AppConfigKeys />}
                  />
                  <Route
                    path={`/${AppRoutes.APP_CONFIG_KEYS}/:${PathParameters.STORE_APP_CONFIG_KEY_ID}`}
                    element={<AppConfigKeysPreview />}
                  />
                  <Route
                    path={`/${AppRoutes.APP_CONFIG_KEYS}/:${PathParameters.STORE_APP_CONFIG_KEY_ID}/${PathParts.EDIT}`}
                    element={<AppConfigKeysEdit />}
                  />
                  <Route
                    path={`/${AppRoutes.APP_CONFIG_KEYS}/${PathParts.CREATE}`}
                    element={<AppConfigKeysCreate />}
                  />
                </>
              )}

              {canReadEnergyDrinks && (
                <>
                  <Route
                    path="/"
                    element={<Navigate to={AppRoutes.ENERGY_DRINKS} />}
                  />
                  <Route
                    path={`${AppRoutes.ENERGY_DRINKS}`}
                    element={<EnergyDrinks />}
                  />
                </>
              )}

              {/* TODO: hide under proper condition based on permissions */}
              {!isProd && (
                <>
                  <Route
                    path={`/${AppRoutes.COMPLAINTS}/`}
                    element={<Complaints />}
                  />
                  <Route
                    path={`/${AppRoutes.COMPLAINTS}/:${PathParameters.COMPLAINT_ID}`}
                    element={<ComplaintPreview />}
                  />
                </>
              )}

              {canManageSupply && (
                <>
                  <Route
                    path={`/${AppRoutes.SUPPLIES}/`}
                    element={<SupplyList />}
                  />
                  <Route
                    path={`/${AppRoutes.SUPPLIES}/${PathParts.CREATE}`}
                    element={<SupplyCreate />}
                  />
                  <Route
                    path={`/${AppRoutes.SUPPLIES}/:${PathParameters.SUPPLY_ID}`}
                    element={<SupplyDetails />}
                  />
                </>
              )}

              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Box>
        </StoreContextProvider>
      </MsalAuthenticationTemplate>
    </>
  );
}

export default App;
