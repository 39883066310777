import { Flex } from "@chakra-ui/react";
import { useFormikContext } from "formik";

import { EFieldType } from "components/formField/FormField";
import { Property } from "components/property";
import StoreField from "pages/store/components/StoreField";
import StoreFieldArray from "pages/store/components/StoreFieldArray";
import {
  componentTypesOptions,
  emptyMaintenanceBreak,
  maintenanceBreakOptions
} from "pages/store/consts";
import { label } from "pages/store/labels";
import {
  EComponent,
  EComponentProperty,
  EPosType,
  EMaintenanceBreak,
  EMaintenanceBreakType,
  EStore,
  TStore
} from "types/configFields";
import { posServiceDatetimeFormatter } from "utils/dateTime";

import { cardStyles } from "../../styles";

import MonitorFields from "./MonitorFields";

const getBreakFieldType = (type: EMaintenanceBreakType) => {
  switch (type) {
    case EMaintenanceBreakType.DAILY:
      return EFieldType.TIME;
    case EMaintenanceBreakType.YEARLY:
    case EMaintenanceBreakType.ONE_TIME:
      return EFieldType.DATETIME;
  }
};

const Pos = () => {
  const { values, setFieldValue } = useFormikContext<TStore>();
  const keyPart = `${EStore.COMPONENTS}.${EComponent.POS}`;
  const currentType =
    values[EStore.COMPONENTS]?.[EComponent.POS][EComponentProperty.TYPE];
  const isPosService = currentType === EPosType.POS_SERVICE;
  const isIpos = currentType === EPosType.IPOS;

  return (
    <>
      <Flex {...cardStyles}>
        <StoreField
          fieldType={EFieldType.TEXT}
          name={`${keyPart}.${EComponentProperty.SERVICE_KEY}`}
        />

        <StoreField
          fieldType={EFieldType.SELECT}
          name={`${keyPart}.${EComponentProperty.TYPE}`}
          options={componentTypesOptions}
        />
      </Flex>

      {isPosService && (
        <>
          <Flex {...cardStyles}>
            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.WORKER_ID}`}
            />

            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.POS_IP_ADDRESS}`}
            />

            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.POS_PORT}`}
            />

            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.POS_USERNAME}`}
            />

            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.POS_PASSWORD}`}
            />

            {/* <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.POS_WAN_ADDRESS}`}
            /> */}

            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.OUTAGE_THRESHOLD}`}
            />

            <StoreField
              fieldType={EFieldType.TEXT}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.NO_OUTAGE_THRESHOLD}`}
            />

            <StoreField
              fieldType={EFieldType.CHECKBOX}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.USE_STORE_COUPONS_POOL_LIST}`}
            />

            <StoreField
              fieldType={EFieldType.CHECKBOX}
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.IS_BIG_RED_BOX_PROMO_ENABLED}`}
            />

            <StoreFieldArray
              name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}`}
              header="Lista zdefiniowanych przerw serwisowych dla danego sklepu"
              addButtonText="Dodaj nową przerwę serwisową"
              emptyArrayText="Brak przerw serwisowych"
              emptyItem={emptyMaintenanceBreak}
              renderComponent={(item, index) => (
                <>
                  <StoreField
                    isSimpleField
                    fieldType={EFieldType.SELECT}
                    name={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.${index}.${EMaintenanceBreak.TYPE}`}
                    labelOverride={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.0.${EMaintenanceBreak.TYPE}`}
                    options={maintenanceBreakOptions}
                    extendOnChange={(value, prevValue) => {
                      if (value !== prevValue) {
                        setFieldValue(
                          `${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.${index}.${EMaintenanceBreak.STARTS_AT}`,
                          ""
                        );
                        setFieldValue(
                          `${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.${index}.${EMaintenanceBreak.ENDS_AT}`,
                          ""
                        );
                      }
                    }}
                  />
                  {!!item.type && (
                    <>
                      <StoreField
                        isSimpleField
                        fieldType={getBreakFieldType(item.type)}
                        name={`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.${index}.${EMaintenanceBreak.STARTS_AT}`}
                        labelOverride={`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.0.${EMaintenanceBreak.STARTS_AT}`}
                        isYearVisible={
                          item.type === EMaintenanceBreakType.ONE_TIME
                        }
                      />
                      <StoreField
                        isSimpleField
                        fieldType={getBreakFieldType(item.type)}
                        name={`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.${index}.${EMaintenanceBreak.ENDS_AT}`}
                        labelOverride={`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.0.${EMaintenanceBreak.ENDS_AT}`}
                        isYearVisible={
                          item.type === EMaintenanceBreakType.ONE_TIME
                        }
                      />
                    </>
                  )}
                </>
              )}
              renderDisabledComponent={(item, index) => (
                <>
                  <Property
                    data-searchid={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.${index}.${EMaintenanceBreak.TYPE}`}
                    label={
                      label[
                        `${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.0.${EMaintenanceBreak.TYPE}` as keyof typeof label
                      ]
                    }
                    wordBreak="break-all"
                  >
                    {item.type}
                  </Property>
                  <Property
                    data-searchid={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.${index}.${EMaintenanceBreak.STARTS_AT}`}
                    label={
                      label[
                        `${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.0.${EMaintenanceBreak.STARTS_AT}` as keyof typeof label
                      ]
                    }
                    wordBreak="break-all"
                  >
                    {posServiceDatetimeFormatter(
                      item.startsAt,
                      item.type,
                      false
                    )}
                  </Property>
                  <Property
                    data-searchid={`${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.${index}.${EMaintenanceBreak.ENDS_AT}`}
                    label={
                      label[
                        `${keyPart}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.0.${EMaintenanceBreak.ENDS_AT}` as keyof typeof label
                      ]
                    }
                    wordBreak="break-all"
                  >
                    {posServiceDatetimeFormatter(item.endsAt, item.type, false)}
                  </Property>
                </>
              )}
            />
          </Flex>

          <Flex w="full" gap="1rem">
            <MonitorFields
              title="Availability monitor"
              monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.AVAILABILITY}`}
            />

            <MonitorFields
              title="Paper state monitor"
              monitorPath={`${keyPart}.${EComponentProperty.MONITORS}.${EComponentProperty.PAPER_STATE}`}
            />
          </Flex>
        </>
      )}

      {isIpos && (
        <Flex {...cardStyles}>
          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${keyPart}.${EComponentProperty.IPOS}.${EComponentProperty.TRONITAG_ID}`}
          />

          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${keyPart}.${EComponentProperty.IPOS}.${EComponentProperty.FAKTUROWNIA_ID}`}
          />
        </Flex>
      )}
    </>
  );
};

export default Pos;
