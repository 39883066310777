import pick from "lodash/pick";

import { Product, SupplyStatus } from "../types";

import { columns } from "./consts";

export const prepareTableData = (data?: Product[]) => {
  if (!data) {
    return [];
  }

  return data.map((item, index) => {
    return {
      LP: index + 1,
      ...pick(item, columns)
    };
  });
};

export const statusToButtonLabelMap = new Map<SupplyStatus, string>([
  [SupplyStatus.SENT, "Oznacz jako potwierdzone"],
  [SupplyStatus.CONFIRMED, "Oznacz jako zakończone"],
  [SupplyStatus.FINISHED, "Zamówienie zakończone"]
]);
