import { EditablePermissions, ReadOnlyPermissions, Roles } from "./enums";

type TReadOnlyPermissions = {
  [T in ReadOnlyPermissions]: Roles[];
};

type TEditablePermissions = {
  [T in EditablePermissions]: Exclude<Roles, Roles.READ_ONLY>[];
};

// based on
// https://netguru.atlassian.net/wiki/spaces/AN/pages/3925704775/Role+u+ytkownik+w+Panelu+Administracyjnego
// v. 15, Mar 22, 2024
export const definedPermissions: TReadOnlyPermissions & TEditablePermissions = {
  [ReadOnlyPermissions.canReadStores]: [
    Roles.READ_ONLY,
    Roles.OPERATIONS,
    Roles.CUSTOMER_SERVICE, // TBD
    Roles.SURVEILLANCE,
    Roles.SUPPLY
  ],
  [ReadOnlyPermissions.canReadStoreDetails]: [
    Roles.READ_ONLY,
    Roles.OPERATIONS
  ],
  [EditablePermissions.canEditStores]: [Roles.OPERATIONS],
  [ReadOnlyPermissions.canReadStoreChains]: [Roles.READ_ONLY, Roles.OPERATIONS],
  [EditablePermissions.canEditStoreChains]: [], // only admin can manage this
  [EditablePermissions.canEditAppConfigKeys]: [], // only admin can manage this
  [ReadOnlyPermissions.canReadEnergyDrinks]: [
    Roles.READ_ONLY,
    Roles.OPERATIONS,
    Roles.SURVEILLANCE
  ],
  [EditablePermissions.canChangeEnergyDrinksServiceMode]: [Roles.OPERATIONS],
  [EditablePermissions.canLockEnergyDrinks]: [Roles.SURVEILLANCE],
  [ReadOnlyPermissions.canReadComplaints]: [
    Roles.READ_ONLY,
    Roles.OPERATIONS,
    Roles.CUSTOMER_SERVICE,
    Roles.SURVEILLANCE
  ],
  [EditablePermissions.canAcceptComplaints]: [Roles.CUSTOMER_SERVICE],
  [ReadOnlyPermissions.canReadClients]: [
    Roles.READ_ONLY,
    Roles.OPERATIONS,
    Roles.CUSTOMER_SERVICE,
    Roles.SURVEILLANCE
  ],
  [EditablePermissions.canAnonymiseClients]: [Roles.CUSTOMER_SERVICE],
  [ReadOnlyPermissions.canReadOrders]: [
    Roles.READ_ONLY,
    Roles.OPERATIONS,
    Roles.CUSTOMER_SERVICE,
    Roles.SURVEILLANCE
  ],
  [EditablePermissions.canCancelOrders]: [Roles.CUSTOMER_SERVICE],
  [EditablePermissions.canMarkFrauds]: [
    Roles.CUSTOMER_SERVICE,
    Roles.SURVEILLANCE // TBD
  ],
  [ReadOnlyPermissions.canReadProducts]: [
    Roles.READ_ONLY,
    Roles.OPERATIONS,
    Roles.CUSTOMER_SERVICE
  ],
  [EditablePermissions.canEditProducts]: [
    Roles.OPERATIONS,
    Roles.CUSTOMER_SERVICE
  ],
  [ReadOnlyPermissions.canReadMarketingContent]: [
    Roles.READ_ONLY,
    Roles.MARKETING
  ],
  [EditablePermissions.canEditMarketingContent]: [Roles.MARKETING],
  [EditablePermissions.canManageSupply]: [Roles.SUPPLY]
};
