export enum Roles {
  ADMIN = "admin.account",
  READ_ONLY = "admin.readOnly",
  OPERATIONS = "admin.operations",
  CUSTOMER_SERVICE = "admin.customerService",
  SURVEILLANCE = "admin.surveillance",
  MARKETING = "admin.marketing",
  SUPPLY = "admin.supplyManager"
}

export enum ReadOnlyPermissions {
  canReadStores = "canReadStores",
  canReadStoreDetails = "canReadStoreDetails",
  canReadStoreChains = "canReadStoreChains",
  canReadEnergyDrinks = "canReadEnergyDrinks",
  canReadComplaints = "canReadComplaints",
  canReadClients = "canReadClients",
  canReadOrders = "canReadOrders",
  canReadProducts = "canReadProducts",
  canReadMarketingContent = "canReadMarketingContent"
}

export enum EditablePermissions {
  canEditStores = "canEditStores",
  canEditStoreChains = "canEditStoreChains",
  canEditAppConfigKeys = "canEditAppConfigKeys",
  canChangeEnergyDrinksServiceMode = "canChangeEnergyDrinksServiceMode",
  canLockEnergyDrinks = "canLockEnergyDrinks",
  canAcceptComplaints = "canAcceptComplaints",
  canAnonymiseClients = "canAnonymiseClients",
  canMarkFrauds = "canMarkFrauds",
  canCancelOrders = "canCancelOrders",
  canEditProducts = "canEditProducts",
  canEditMarketingContent = "canEditMarketingContent",
  canManageSupply = "canManageSupply"
}
