import { Center, Spinner } from "@chakra-ui/react";

interface ICustomSpinner {
  h?: string;
  size?: string;
}

export const CustomSpinner = ({ h = "6rem", size = "xl" }: ICustomSpinner) => {
  return (
    <Center h={h}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size={size}
      />
    </Center>
  );
};
