import { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Text,
  Flex
} from "@chakra-ui/react";

import FileDropzone from "components/fileDropzone/FileDropzone";

import { buttonStyles, buttonStylesLight } from "../styles";

interface NewSupplyModalProps {
  buttonText: string;
  processUploadedFile: (file: File) => void;
}

const NewSupplyModal = ({
  buttonText,
  processUploadedFile
}: NewSupplyModalProps) => {
  const [tempCsvFile, setTempCsvFile] = useState<File | undefined>();

  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal
  } = useDisclosure();

  const handleClosingModal = () => {
    setTempCsvFile(undefined);
    closeModal();
  };

  return (
    <>
      <Button
        height="48px"
        {...buttonStylesLight}
        p="0.75rem 1.5rem"
        onClick={openModal}
      >
        {buttonText}
      </Button>
      <Modal isOpen={isModalOpen} onClose={handleClosingModal}>
        <ModalOverlay />
        <ModalContent maxWidth="480px">
          <ModalBody p="2.5rem">
            <Flex flexDir="column" alignItems="center">
              <Text
                fontSize="fs20"
                color="neutral.950"
                fontWeight={700}
                letterSpacing="-0.3px"
                textAlign="center"
                mb="1.5rem"
              >
                Dodaj plik
              </Text>
              <Text
                fontSize="fs14"
                color="neutral.600"
                letterSpacing="-0.21px"
                textAlign="center"
                mb="3.5rem"
              >
                Po przesłaniu Twojego pliku system automatycznie przeprowadzi
                jego walidację i wyświetli zawartość pliku.
              </Text>
              <FileDropzone
                onDrop={file => {
                  setTempCsvFile(file[0]);
                }}
              />
              <Button
                mt="3.5rem"
                {...buttonStyles}
                isDisabled={!tempCsvFile?.name}
                onClick={() => {
                  if (tempCsvFile) {
                    processUploadedFile(tempCsvFile);
                  }
                  handleClosingModal();
                }}
              >
                Gotowe, sprawdź zamówienie
              </Button>
              <Button
                variant="ghost"
                mt="0.5rem"
                p="12px 24px"
                onClick={handleClosingModal}
              >
                Anuluj
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewSupplyModal;
