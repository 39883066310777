import { useEffect, useState } from "react";

import { API_ROOT_SAPOLLO } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";

import { Supply } from "../types";

const INITIAL_MAX_RESULTS = 25;

export const useGetSupplies = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [finalData, setFinalData] = useState<Supply[]>();
  const { error, fetchData } = useFetchRefactored<null, Supply[]>({
    url: `${API_ROOT_SAPOLLO}/supplies`,
    method: "GET",
    queryParams: {
      maxResults: INITIAL_MAX_RESULTS
    }
  });

  useEffect(() => {
    let maxResults = INITIAL_MAX_RESULTS;

    const fetchSupplyList = async () => {
      const res = await fetchData({ queryParams: { maxResults } });
      if (res.data) {
        if (res.data.length < maxResults) {
          setIsLoading(false);
          setFinalData(res.data);
        }

        if (res.data.length === maxResults) {
          maxResults += INITIAL_MAX_RESULTS;
          fetchSupplyList();
        }
      }
    };

    fetchSupplyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data: finalData, isLoading, error };
};
