import { Product, ProductColumns } from "../types";

export const columnLabels = {
  [ProductColumns.MPK]: "MPK",
  [ProductColumns.SAP_ID]: "Indeks",
  [ProductColumns.NAME]: "Nazwa",
  [ProductColumns.OUTCOME_UNIT]: "Jednostka wydania",
  [ProductColumns.PRODUCT_GROUP]: "Grupa towarowa",
  [ProductColumns.ORDERED_QUANTITY]: "Ilość do zamówienia"
};

export const columns = [
  ProductColumns.MPK,
  ProductColumns.SAP_ID,
  ProductColumns.NAME,
  ProductColumns.OUTCOME_UNIT,
  ProductColumns.PRODUCT_GROUP,
  ProductColumns.ORDERED_QUANTITY
] as const;

export const csvColumnTranslationMap = new Map<string, keyof Product>([
  ["mpk", ProductColumns.MPK],
  ["indeks", ProductColumns.SAP_ID],
  ["nazwa", ProductColumns.NAME],
  ["jednostka wydania", ProductColumns.OUTCOME_UNIT],
  ["grupa towarowa", ProductColumns.PRODUCT_GROUP],
  ["ilość do zamówienia", ProductColumns.ORDERED_QUANTITY]
]);
