import { formatISO } from "date-fns";
import omit from "lodash/omit";

import { Product } from "../types";

export const validateSapId = (sapId: string) => {
  if (!sapId) {
    return false;
  }

  const regex = /^\d{8,10}$/;
  return regex.test(sapId);
};

export const prepareSupplyPayload = (
  values: Product[],
  requestedDeliveryDate: Date
) => {
  const productsWithItemNumber = values.map((item, index) => ({
    itemNumber: index,
    ...omit(item, "mpk")
  }));

  return {
    requestedDeliveryDate: formatISO(requestedDeliveryDate),
    products: productsWithItemNumber
  };
};
