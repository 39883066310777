import { ReactNode } from "react";
import { Icon, Flex, Text, useClipboard } from "@chakra-ui/react";
import { CopyIcon, CopyCheckIcon } from "lucide-react";

interface LineItemProps {
  label: string;
  value?: string;
  customContent?: () => ReactNode;
  hasCopyButton?: boolean;
}

const LineItem = ({
  label,
  value,
  customContent,
  hasCopyButton = true
}: LineItemProps) => {
  const { onCopy, hasCopied } = useClipboard(value || "");

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Text
        color="neutral.800"
        fontSize="0.75rem"
        fontWeight={600}
        lineHeight="1rem"
        letterSpacing="-0.18px"
      >
        {label}
      </Text>
      <Flex gap="0.75rem" alignItems="center">
        {customContent && customContent()}
        {value && (
          <Text
            color="blue.700"
            fontSize="0.75rem"
            fontWeight={600}
            lineHeight="1rem"
            letterSpacing="-0.18px"
          >
            {value}
          </Text>
        )}
        {hasCopyButton && value && (
          <Icon
            fontSize="0.75rem"
            color="blue.700"
            cursor="pointer"
            onClick={onCopy}
            _active={{
              transform: "translateY(2px)",
              transition: "0.15s ease"
            }}
          >
            {hasCopied ? (
              <CopyCheckIcon strokeWidth="3px" />
            ) : (
              <CopyIcon strokeWidth="3px" />
            )}
          </Icon>
        )}
      </Flex>
    </Flex>
  );
};

export default LineItem;
