import { API_ROOT_SAPOLLO } from "consts";
import { useFetchRefactored } from "hooks/useFetchRefactored";

import { SupplyDetailsResponse } from "../types";

export const useGetSupplyDetails = (id?: string) => {
  const fetch = useFetchRefactored<null, SupplyDetailsResponse>({
    url: `${API_ROOT_SAPOLLO}/supplies/${id}`,
    method: "GET"
  });
  return fetch;
};
